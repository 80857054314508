body {
  margin: 0;
  font-family: "Jost", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-weight: 600;
}

.title {
  font-size: 36px;
  line-height: 42px;
  letter-spacing: -1px;
  margin-bottom: 20px;
  text-align: center;
}

.small-border {
  width: 100px;
  height: 3px;
  border-top: solid 2px #9db9c7;
  border-left: none;
  border-right: none;
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
}

.btn-primary {
  background: #9db9c7 !important;
  border-color: #9db9c7 !important;
  padding: 0.375rem 1rem !important;
}

.btn-call{
  background: #111111 !important;
  border-color: #111111 !important;
  padding: 0.375rem 1rem !important;
  color: #fff !important;
  padding: 10px 60px !important;
}